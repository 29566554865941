const translations = {
  'loading': 'Nalaganje.',
  'app.error': 'Napaka.',
  'app.search': 'Išči',
  'app.search.label': 'Razvrščanje',
  'app.search.position': 'naslov / kraj',
  'app.search.fetchingPosition': 'iščem lokacijo',
  'app.search.locateMe': 'Moja lokacija',
  'app.search.radius': 'oddaljenost',
  'app.search.name': 'prodajno mesto',
  'app.search.franchise': 'distributer',
  'app.search.by_distance': 'po oddaljenosti',
  'results.load_more': 'Več',
  'place.directions': 'Navodila',
  'place.open_hours': 'Odpiralni časi',
  'place.direction': 'Smer',
}

export default translations
