export const GOOGLE_API_KEY =
  process.env.NODE_ENV === 'production' && !process.env.REACT_APP_STAGING
    ? 'AIzaSyBTRv5ZIZgQpKoxN1-fJrKoguR8aiWVcPc'
    : 'AIzaSyADWNDN3IINUrg5THhDOty2NWFDhUAcyRk'

export let BASE_URL

if (process.env.NODE_ENV === 'production') {
  BASE_URL = ''
} else if (process.env.REACT_APP_PRODUCTION_API) {
  BASE_URL =  'https://novo.goriva.si'
} else {
  BASE_URL = ''
}

//openstreetmap-tile-server
//export const LEAFLET_TILES_URL = 'http://localhost:8080/tile/{z}/{x}/{y}.png'

//maptiler/tileserver-g
//export const LEAFLET_TILES_URL = 'http://localhost:8081/styles/basic-preview/{z}/{x}/{y}{r}.png'

// docker container nginx map-server
//export const LEAFLET_TILES_URL =
//'http://localhost:9009/styles/basic-preview/{z}/{x}/{y}{r}.png'

// leafletTilesUrl from config is used, this is fallback
export const LEAFLET_TILES_URL =
  'https://maps.goriva.si/styles/basic-preview/{z}/{x}/{y}{r}.png'

//const LEAFLET_TILES_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}{r}.png'
export const LEAFLET_TILES_ATTRIBUTION = `
<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a>
<a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>
`

export const MAP_BOUNDS = [
  [45.3772, 13.2944],
  [46.9233, 16.6345],
]
