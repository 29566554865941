import axios from 'axios'
import { BASE_URL } from '../constants/api'

/**
 * Returns annotated places.
 *
 * @param {searchParams} object
 * @return {object}
 */
export const getPlaces = async (searchParams, url) => {
  const result = await axios.get(url || BASE_URL + '/api/v1/search/', {
    params: searchParams,
  })
  return result.data
}

export const loadFuelCollection = async () => {
  const result = await axios.get(BASE_URL + '/api/v1/fuel/')
  return result.data
}

export const loadFranchiseCollection = async () => {
  const result = await axios.get(BASE_URL + '/api/v1/franchise/')
  return result.data
}


export const loadSettings = async () => {
  const result = await axios.get(BASE_URL + '/api/v1/settings/')
  return result.data
}
