import React from 'react'
import { Formik, Field } from 'formik'
import { injectIntl, FormattedMessage } from 'react-intl'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import Geolocation from './Geolocation'

const SEARCH_RADIUS = [1, 5, 10, 20, 50]

const validate = (values) => {
  let errors = {}

  if (!values.position) {
    errors.position = 'Zahtevano'
  }
  return errors
}

const SearchForm = ({
  fetchingPosition,
  onSearch,
  initialValues,
  fuelCollection,
  franchiseCollection,
  intl,
  onLocationFound,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        onSearch(values)
        setSubmitting(false)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form
          className="grid grid-cols-1 gap-y-2 sm:gap-y-6 gap-x-4 sm:gap-x-6 sm:grid-cols-6 lg:gap-x-8"
          onSubmit={handleSubmit}
        >
          <div className="sm:col-span-3 form-group search-form__form-group search-form__position">
            <label className="form-label" hidden>
              <FormattedMessage id="app.search.position" />
            </label>

            <Geolocation
              lazy
              timeout={60000}
              maximumAge={30000}
              onError={console.error}
              onSuccess={(position) => {
                onLocationFound(position, values)
              }}
              render={(locBag) => (
                <div className="flex space-x-2">
                  <Field
                    type="text"
                    name="position"
                    className={classNames(
                      '',
                      'search-form__position-input',
                      'form-control',
                      {
                        'is-invalid': touched.position && errors.position,
                      }
                    )}
                    placeholder={intl.formatMessage({
                      id: 'app.search.position',
                    })}
                  />
                  <button
                    type="button"
                    disabled={locBag.fetchingPosition}
                    onClick={locBag.getCurrentPosition}
                    className="btn btn-secondary flex-grow-0 mt-1 flex items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="ml-1 search-form__button-text">
                      {fetchingPosition
                        ? intl.formatMessage({
                            id: 'app.search.fetchingPosition',
                          })
                        : intl.formatMessage({ id: 'app.search.locateMe' })}
                    </span>
                  </button>
                </div>
              )}
            />
          </div>

          <div className="sm:col-span-3 form-group search-form__form-group search-form__radius">
            <label className="form-label" hidden>
              <FormattedMessage id="app.search.radius" />
            </label>
            <Field name="radius">
              {({ field, form, meta }) => (
                <select className="form-control" {...field}>
                  <option value="">
                    {intl.formatMessage({ id: 'app.search.radius' })}
                  </option>
                  {SEARCH_RADIUS.map((radius) => (
                    <option key={radius} value={radius * 1000}>
                      {radius} km
                    </option>
                  ))}
                </select>
              )}
            </Field>
          </div>

          <div className="sm:col-span-3 lg:col-span-2 form-group search-form__form-group">
            <label className="form-label" hidden>
              <FormattedMessage id="app.search.name" />
            </label>
            <Field name="name">
              {({ field, form, meta }) => (
                <input
                  type="text"
                  className="form-control"
                  {...field}
                  placeholder={intl.formatMessage({ id: 'app.search.name' })}
                />
              )}
            </Field>
          </div>

          <div className="sm:col-span-3 lg:col-span-2 form-group search-form__form-group">
            <label className="form-label" hidden>
              <FormattedMessage id="app.search.franchise" />
            </label>
            <Field name="franchise">
              {({ field, form, meta }) => (
                <select className="form-control" {...field}>
                  <option value="">
                    {intl.formatMessage({ id: 'app.search.franchise' })}
                  </option>
                  {franchiseCollection.map((franchise) => (
                    <option key={franchise.pk} value={franchise.pk}>
                      {franchise.name}
                    </option>
                  ))}
                </select>
              )}
            </Field>
          </div>

          <div className="sm:col-span-3 lg:col-span-2 form-group search-form__form-group">
            <label className="form-label" hidden>
              <FormattedMessage id="app.search.label" />
            </label>
            <Field name="o">
              {({ field, form, meta }) => (
                <select className="form-control text-sm sm:text-[13px]" {...field}>
                  <option value="distance">
                    {intl.formatMessage({ id: 'app.search.by_distance' })}
                  </option>
                  {fuelCollection &&
                    fuelCollection.map((fuel) => (
                      <option key={fuel.pk} value={'price_' + fuel.code}>
                        {fuel.long_name} ({fuel.name})
                      </option>
                    ))}
                </select>
              )}
            </Field>
          </div>

          <div className="self-end sm:col-span-3 lg:col-span-6 search-form__form-group">
            <button
              className="btn btn-primary w-full search-form__search-btn"
              color="primary"
              disabled={!isEmpty(errors) || isSubmitting}
              type="submit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
              <span className="ml-1">
                <FormattedMessage id="app.search" defaultMessage="search" />
              </span>
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default injectIntl(SearchForm)
