import React from 'react'
import classNames from 'classnames'
import { map } from 'lodash'
import { FormattedMessage } from 'react-intl'

import backIcon from './baseline-arrow_back-24px.svg'

const formatPrice = (price) => (price ? Number(price).toFixed(3) + '€' : '-')

const FuelPrice = ({ code, name, price }) =>
  price && (
    <div className={`fuel-price fuel-price--${code}`}>
      <div code={code} title={name} className="fuel-price__price">
        {formatPrice(price)}
      </div>
      <div title={name} className="fuel-price__label">
        {name}
      </div>
    </div>
  )

const Prices = ({ prices, fuelCollection }) => (
  <div className="flex flex-wrap gap-x-2 place__prices">
    {map(fuelCollection, ({ code, name }) => (
      <FuelPrice key={code} code={code} name={name} price={prices[code]} />
    ))}
  </div>
)

const Distance = ({ value }) => {
  if (!value) {
    return null
  }
  const formatedValue = Number(value / 1000).toFixed(1) + ' km'
  return (
    <span className="mt-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-secondary-200 font-bold text-gray-800">
      {formatedValue}
    </span>
  )
}

const directionsLink = (place, position) => {
  let url = `https://www.google.com/maps/dir/?api=1&destination=${place.lat},${place.lng}&travelmode=driving`
  if (position) {
    url = url + `&origin=${position.lat},${position.lng}`
  }
  return url
}

const Place = ({
  isOpened,
  handleOpen,
  handleClose,
  handleMouseEnter,
  handleMouseLeave,
  item,
  fuelDict,
  fuelCollection,
  franchiseDict,
  position,
}) => {
  const placeClassName = classNames('results__item', {
    'results__item--selected': isOpened,
  })
  return (
    <li
      className={placeClassName}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(e) => !isOpened && handleOpen(item)}
    >
      {isOpened && (
        <button
          className="flex mb-4 place__header-actions"
          onClick={(e) => handleClose(item)}
        >
          <img src={backIcon} alt="Rezultati" />
          Rezultati
        </button>
      )}
      {isOpened && (
        <div className="place__franchise">
          {franchiseDict[item.franchise].name}
        </div>
      )}
      <div className="place__header">
        <h5 className="text-lg font-bold place__title">{item.name}</h5>
      </div>
      <div className="mt-2">
        <Prices prices={item.prices} fuelCollection={fuelCollection} />
      </div>
      <div className="mt-2 place__description">{item.address}</div>
      <Distance value={item.distance} />
      <div className="">
        {isOpened && (
          <div className="mt-8 place__additional">
            <div className="place__open-hours">
              <h6 className="font-bold">
                <FormattedMessage id="place.open_hours" />:
              </h6>
              <p className="place__open-hours-description">{item.open_hours}</p>
            </div>
            {item.direction && (
              <div className="place__direction">
                <h6 className="font-bold">
                  <FormattedMessage id="place.direction" />:{item.direction}
                </h6>
              </div>
            )}
            <div className="mt-8 place__directions">
              <a
                className="btn btn-secondary w-full"
                href={directionsLink(item, position)}
              >
                <FormattedMessage id="place.directions" />
              </a>
            </div>
          </div>
        )}
      </div>
    </li>
  )
}

const PlaceList = ({
  places,
  opened,
  handleOpen,
  handleClose,
  handleMouseEnter,
  handleMouseLeave,
  fuelDict,
  fuelCollection,
  franchiseDict,
  position,
}) => {
  if (places === null) return <div>Nalagam....</div>
  return (
    <ul className={classNames('results', { 'results--opened': !!opened })}>
      {places.map((place) => (
        <Place
          isOpened={opened === place.pk}
          key={place.pk}
          item={place}
          handleMouseEnter={() => handleMouseEnter(place)}
          handleMouseLeave={() => handleMouseLeave(place)}
          handleOpen={() => handleOpen(place)}
          handleClose={() => handleClose(place)}
          fuelDict={fuelDict}
          fuelCollection={fuelCollection}
          franchiseDict={franchiseDict}
          position={position}
        />
      ))}
    </ul>
  )
}

export default PlaceList
