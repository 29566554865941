import React, { Component } from 'react'

import { IntlProvider } from 'react-intl'
import messages from './locale/sl'

import { BrowserRouter as Router, Route } from 'react-router-dom'

import Home from './pages/Home'
import About from './pages/About'
import Nav from './components/Nav'
import Disclaimer from './components/Disclaimer'
import { loadFuelCollection, loadFranchiseCollection, loadSettings } from './utils/api'

import './index.css';
//require('./style.css')

const getConfig = async () => {
  const fuelCollection = await loadFuelCollection()
  const franchiseCollection = await loadFranchiseCollection()
  const settings = await loadSettings()
  const fuelDict = fuelCollection.reduce((obj, item) => {
    obj[item.code] = item
    return obj
  }, {})
  const franchiseDict = franchiseCollection.reduce((obj, item) => {
    obj[item.pk] = item
    return obj
  }, {})
  return {
    franchiseCollection,
    fuelCollection,
    fuelDict,
    franchiseDict,
    settings,
  }
}


class App extends Component {
  state = {
    config: null,
    loadError: false,
  }

  componentDidMount() {
    getConfig()
      .then(config => this.setState({ config }))
      .catch(e => this.setState({ loadError: true }))
  }

  render() {
    if (this.state.loadError) {
      return (
        <div className="container">
          <p>Napaka</p>
          <p>
            <a className="btn btn-primary" href="/">
              Poskusi ponovno
            </a>
          </p>
        </div>
      )
    }
    if (!this.state.config) {
      return <div className="container mx-auto">Nalagam...</div>
    }
    return (
      <IntlProvider locale="sl" messages={messages}>
        <Router>
          <div className="h-full">
            <Nav />
            { false && <Disclaimer /> }
            <Route
              exact
              path="/"
              render={props => (
                <Home
                  {...props}
                  fuelCollection={this.state.config.fuelCollection}
                  fuelDict={this.state.config.fuelDict}
                  franchiseCollection={this.state.config.franchiseCollection}
                  franchiseDict={this.state.config.franchiseDict}
                  leafletTilesUrl={this.state.config.settings.leaflet_tiles_url}
                />
              )}
            />
            <Route path="/about" component={About} />
          </div>
        </Router>
      </IntlProvider>
    )
  }
}

export default App
