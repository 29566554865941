import shallowEqual from 'shallowequal'

function getMaxDistance(places, position, attribute) {
  let max = 0
  places.forEach((place) => {
    const distance = position[attribute] - place[attribute]
    if (Math.abs(distance) > Math.abs(max)) {
      max = distance
    }
  })
  return max
}

export function getBounds(places, position) {
  const bounds = new window.google.maps.LatLngBounds()
  places.forEach((bound) =>
    bounds.extend(new window.google.maps.LatLng(bound.lat, bound.lng))
  )
  if (position) {
    bounds.extend(new window.google.maps.LatLng(position.lat, position.lng))

    bounds.extend(
      new window.google.maps.LatLng(
        position.lat + getMaxDistance(places, position, 'lat'),
        position.lng + getMaxDistance(places, position, 'lng')
      )
    )
  }
  return bounds
}

const defaultMarkerHover = process.env.PUBLIC_URL + '/marker_hover.svg'
const defaultMarker = process.env.PUBLIC_URL + '/marker.svg'

/**
 * Returns marker or default marker icon URL.
 *
 * @param {} marker
 * @param {} markerHover
 * @param {} hover
 */
export function getMarkerIconURL(marker, markerHover, hover = false) {
  if (hover) {
    return markerHover || marker || defaultMarkerHover
  }
  return marker || defaultMarker
}

/**
 * Returns map bounds to fit markers only if places or position changed.
 *
 * @param {} prevProps
 * @param {} props
 */
export function placesOrPositionChanged(prevProps, props) {
  return (
    !shallowEqual(prevProps.places, props.places) ||
    !shallowEqual(prevProps.position, props.position)
  )
}
