export default function Nav() {
  return (
    <div className="h-[56px] fixed top-0 left-0 right-0 bg-secondary px-4 sm:px-6 flex items-center justify-between">
      <a className="text-white text-xl" href="/">
        Cene naftnih derivatov
      </a>
      <a className="text-white text-sm" href="/accounts/profile/">
        Nadzorna plošča
      </a>
    </div>
  )
}
