import React from 'react'

import { shallowEqual } from '../utils/fetch'
import { getPlaces } from '../utils/api'


export default class FetchLocations extends React.Component {
  state = {
    places: null,
    next: null,
  }

  _getPlaces = () => {
    if (!this.props.searchParams.position) {
      return
    }
    getPlaces(this.props.searchParams).then(res => {
      this.setState({
        places: res.results,
        position: res.position,
        next: res.next,
      })
    })
  }

  componentDidMount() {
    this._getPlaces()
  }

  componentDidUpdate(prevProps) {
    const prevParams = prevProps.searchParams
    const currentParams = this.props.searchParams
    if (!shallowEqual(prevParams, currentParams)) {
      this._getPlaces()
    }
  }

  loadMore = () => {
    getPlaces({}, this.state.next).then(res => {
      this.setState({
        places: [...this.state.places, ...res.results],
        position: res.position,
        next: res.next,
      })
    })
  }

  render = () => {
    const onLoadMore = this.state.next ? this.loadMore : null
    return this.props.render(this.state.places, this.state.position, onLoadMore)
  }
}


