import React from 'react'

const Disclaimer = () => {
  return (
  <div className="alert alert-warning disclaimer">
    Trenutno prihaja do motenj pri dostopu na stran goriva.si zaradi povečanega števila uporabnikov. Opravičujemo se vam za nastale nevšečnosti in vas vabimo na obisk strani nekoliko kasneje.
</div>
)
}

export default Disclaimer
